import {
  createPopper,
  flip,
  type Instance,
  type Modifier,
  offset,
  type Options,
  type Placement,
  type PositioningStrategy,
  preventOverflow,
} from "@popperjs/core";
import {
  type ComputedRef,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  type Ref,
  watch,
} from "vue";
import { SM_BREAKPOINT } from "./constants";

function remToPx(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const usePopup = (
  dialogElement: Ref<
    HTMLElement | { elementNode: HTMLElement | undefined } | undefined
  >,
  attachToElement: Ref<
    Element | { elementNode: Element | undefined } | undefined
  >,
  visible: Ref<boolean> | ComputedRef<boolean>,
  remOffset?: [number, number],
  strategy: PositioningStrategy = "absolute",
  sameWidth = false,
  placement: Placement = "bottom-start",
) => {
  const popper = ref<Instance>();

  onMounted(() => {
    window.addEventListener("resize", refreshPopup);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", refreshPopup);
  });

  onUpdated(() => {
    if (popper.value) {
      popper.value.update();
    }
  });

  watch(visible, (newValue) => {
    if (newValue && !applyDialogFullScreen()) {
      popper.value = createPopup();
    } else if (!newValue) {
      popper.value?.destroy();
      popper.value = undefined;
    }
  });

  function isElementNode(
    element: Element | { elementNode: Element | undefined } | undefined,
  ): element is { elementNode: Element | undefined } {
    return (
      element !== undefined &&
      (element as { elementNode: Element | undefined }).elementNode !==
        undefined
    );
  }

  const sameWidthModifier: Modifier<"sameWidth", Options> = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${
        (state.elements.reference as HTMLElement).offsetWidth
      }px`;
    },
  };

  function createPopup() {
    if (attachToElement && dialogElement.value) {
      let dialogTarget;
      if (isElementNode(dialogElement.value)) {
        dialogTarget = dialogElement.value.elementNode;
      } else {
        dialogTarget = dialogElement.value;
      }
      let attachToTarget;
      if (isElementNode(attachToElement.value)) {
        attachToTarget = attachToElement.value.elementNode;
      } else {
        attachToTarget = attachToElement.value;
      }
      if (dialogTarget && attachToTarget) {
        return createPopper(attachToTarget, dialogTarget, {
          placement: placement,
          strategy: strategy,
          modifiers: modifiers(),
        });
      }
    }
  }

  const defaultModifiers = [
    preventOverflow,
    flip,
    {
      ...offset,
      options: {
        offset: () => {
          if (remOffset) {
            return [remToPx(remOffset[0]), remToPx(remOffset[1])];
          }
          return [0, 0];
        },
      },
    },
  ];

  function modifiers() {
    if (sameWidth) {
      return [sameWidthModifier, ...defaultModifiers];
    } else {
      return defaultModifiers;
    }
  }

  function refreshPopup() {
    if (popper.value && applyDialogFullScreen()) {
      popper.value.destroy();
      popper.value = undefined;
    } else if (!popper.value && !applyDialogFullScreen()) {
      popper.value = createPopup();
    }
  }

  function applyDialogFullScreen() {
    return (
      isElementNode(dialogElement.value) &&
      document.documentElement.clientWidth <= SM_BREAKPOINT
    );
  }

  function update() {
    if (popper.value) {
      popper.value.update();
    }
  }

  return { update };
};
